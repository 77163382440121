.Toastify__toast--success {
    background-color:#88ff7de3 !important; 
    color: #333 !important;
    border-radius: 5px !important;
    font-weight: bold !important;
}
.Toastify__toast--warning {
    background-color:#fff07ee2 !important; 
    color: #333 !important;
    border-radius: 5px !important;
    font-weight: bold !important;
}
.Toastify__toast--error {
    background-color:#fd8077d5 !important;
    color: #333 !important; 
    border-radius: 5px !important;
    font-weight: bold !important;
}
.Toastify__toast--info{
    background-color:#a1d5ffdb !important; 
    color: #333 !important;
    border-radius: 5px !important;
    font-weight: bold !important;
}

@media only screen and (max-width:480px) {
    .Toastify__toast-container {
     width: 90vw !important;
     padding:0;
     left: 0;
     margin: auto !important;
    }
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-right {
     top: auto !important
    }
    .Toastify__toast-container--bottom-center,
    .Toastify__toast-container--bottom-left,
    .Toastify__toast-container--bottom-right {
     bottom:0
    }
    .Toastify__toast-container--rtl {
     right:0;
     left:auto
    }
}
